import React from "react";
import Div100vh from "react-div-100vh";
import SpButton from "./components/SpButton";
import OpenButton from "./components/OpenButton";
import KontaktButton from "./components/KontaktButton";
import AboutButton from "./components/AboutButton";
import DeliverButton from "./components/DeliverButton";
import CovidButton from "./components/CovidButton";
import BottomNavMob from "./components/BottomNavMob";
import Popup from "./components/Popup";
import logoMobile from "./img/logoMobile.svg";
import Anim from "./js/Anim";
import { useState, useEffect } from "react";
import InfoButton from "./components/InfoButton";
import WagenButton from "./components/WagenButton";
function App() {
  const [timedPopup, setTimedPopup] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTimedPopup(false);
    }, 3000);
  }, []);

  return (
    <Div100vh>
      <Popup trigger={timedPopup} setTrigger={setTimedPopup}>
        <p>
          Liebe Gäste, <br /> wir haben am 22.05.21 und <br /> am 23.05.21
          geschlossen.
          <br />
          Wir sind ab dem 25.05.21 <br /> wieder wie gewohnt für Sie da. <br />{" "}
          <br />
          Wir wünschen Ihnen schöne Pfingsten. <br />
          Ihr Holzhäuser Grill Team.
        </p>
      </Popup>
      <div className="backgroundAnim">
        <Anim />
      </div>
      <div className="navColumn">
        <div className="logo">
          <img className="logoM" src={logoMobile} alt="" />
        </div>
        <div className="navRow">
          <SpButton />
          <OpenButton />
          <KontaktButton />
        </div>
        <div className="navRow">
          <DeliverButton />
          <InfoButton />
          <CovidButton />
        </div>
        <div className="navRow"></div>
      </div>
      <BottomNavMob />
    </Div100vh>
  );
}

export default App;
