import React from "react";

function Open() {
  return (
    <div className="card">
      <h1>Öffnungszeiten</h1>
      <div className="cardIcons">
        <i class="fas fa-business-time"></i>
      </div>
      <p>
        Dienstag - Freitag <br /> 12:00 - 14:00 Uhr <br /> 17:00 - 21:00 Uhr{" "}
        <br /> <br /> Samstag - Sonntag / und Feiertage
        <br /> 16:00 - 21:00 Uhr
      </p>
      <h3 className="card2">
        Letzte Lieferung <br /> 20:30 Uhr
      </h3>
    </div>
  );
}

export default Open;
