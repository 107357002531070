import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Slider {...settings}>
      <div>
        <table>
          <tr>
            <th>VOM GRILL & CO.</th>
          </tr>
          <tr>
            <td>
              Bratwurst <sup>1</sup>
            </td>
            <td>3,50€</td>
          </tr>
          <tr>
            <td>
              Currywurst <sup>1</sup>
            </td>
            <td>3,80€</td>
          </tr>
          <tr>
            <td>
              Zigeunerwurst <sup>1</sup>
            </td>
            <td>3,80€</td>
          </tr>
          <tr>
            <td>
              Schinkenkrakauer <sup>1</sup>
            </td>
            <td>4,20€</td>
          </tr>
          <tr>
            <td>
              *Texasburger <sup></sup>
            </td>
            <td>5,10€</td>
          </tr>
          <tr>
            <td>
              *Spezialburger <sup>5,e,f</sup>
            </td>
            <td>5,10€</td>
          </tr>
          <tr>
            <td>
              *Spezialburger mit Käse <sup>4,5,e,f</sup>
            </td>
            <td>5,30€</td>
          </tr>
          <tr>
            <td>
              Veggieburger <sup></sup>
            </td>
            <td>5,50€</td>
          </tr>
          <tr>
            <td>
              Hot Dog <sup>1,5,f</sup>
            </td>
            <td>5,00€</td>
          </tr>
          <tr>
            <td>
              1/2 Hähnchen <sup></sup>
            </td>
            <td>5,50€</td>
          </tr>
          <tr>
            <td>
              Chicken Picks/Wings <sup>4,a,b</sup>
            </td>
            <td>4,70€</td>
          </tr>
          <tr>
            <td>
              Crunchy Chicken Burger <sup>a,b</sup>
            </td>
            <td>6,50€</td>
          </tr>
          <tr>
            <td>
              Hähnchen Cordon Bleu <sup>a,b</sup>
            </td>
            <td>7,00€</td>
          </tr>
          <tr>
            <td>
              Geflügelschnitzel <sup>a,b</sup>
            </td>
            <td>7,00€</td>
          </tr>
        </table>
      </div>
      <div>
        <table>
          <tr>
            <th>VOM GRILL & CO.</th>
          </tr>
          <tr>
            <td>
              *Kotelett <sup>a,b</sup>
            </td>
            <td>6,70€</td>
          </tr>
          <tr>
            <td>
              *Fleischspieß <sup></sup>
            </td>
            <td>6,70€</td>
          </tr>
          <tr>
            <td>
              *Schnitzel <sup>a,b</sup>
            </td>
            <td>8,00€</td>
          </tr>
          <tr>
            <td>
              *Jägerschnitzel <sup>a,b,c,d,e,f</sup>
            </td>
            <td>9,80€</td>
          </tr>
          <tr>
            <td>
              *Zigeunerschnitzel <sup>a,b,f</sup>
            </td>
            <td>9,80€</td>
          </tr>
          <tr>
            <td>
              *Nackensteak <sup>e</sup>
            </td>
            <td>7,60€</td>
          </tr>
          <tr>
            <td>
              *Hawaiisteak <sup>4,e</sup>
            </td>
            <td>9,80€</td>
          </tr>
          <tr>
            <td>
              *Schweizersteak <sup>4,e</sup>
            </td>
            <td>9,80€</td>
          </tr>
          <tr>
            <td>
              *Amsterdamersteak <sup>4,e</sup>
            </td>
            <td>9,80€</td>
          </tr>
          <tr>
            <td>
              *Hawaiischnitzel <sup>4,e</sup>
            </td>
            <td>10,80€</td>
          </tr>
          <tr>
            <td>
              *Schweizerschnitzel <sup>4,e</sup>
            </td>
            <td>10,80€</td>
          </tr>
          <tr>
            <td>
              *Amsterdamerschnitzel <sup>4,e</sup>
            </td>
            <td>10,80€</td>
          </tr>
          <tr>
            <td>
              *Frikadelle <sup></sup>
            </td>
            <td>3,50€</td>
          </tr>
        </table>
      </div>
      <div>
        <table>
          <tr>
            <th>SALATE/BEILAGEN/SOßEN</th>
          </tr>
          <tr>
            <td>
              *Krautsalat <sup></sup>
            </td>
            <td>3,00€</td>
          </tr>
          <tr>
            <td>
              *Kartoffelsalat <sup>2</sup>
            </td>
            <td>3,00€</td>
          </tr>
          <tr>
            <td>
              *div. Salate nach Wahl <sup></sup>
            </td>
            <td>3,00€</td>
          </tr>
          <tr>
            <td>
              *gemischter Salat <sup></sup>
            </td>
            <td>6,00€</td>
          </tr>
          <tr>
            <td>
              *Holzhäuser Salat <sup></sup>
            </td>
            <td>7,00€</td>
          </tr>
          <tr>
            <td>
              {" "}
              <sup></sup>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              Pommes frites (Groß) <sup></sup>
            </td>
            <td>3,00€</td>
          </tr>
          <tr>
            <td>
              Kroketten/Wedges/Röstis<sup></sup>
            </td>
            <td>3,00€</td>
          </tr>
          <tr>
            <td>
              Backkartoffel mit Dressing<sup>b,c,f</sup>
            </td>
            <td>4,00€</td>
          </tr>
          <tr>
            <td>
              {" "}
              <sup></sup>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              Ketchup<sup></sup>
            </td>
            <td>0,40€</td>
          </tr>
          <tr>
            <td>
              Mayonnaise<sup>2,a</sup>
            </td>
            <td>0,40€</td>
          </tr>
          <tr>
            <td>
              *Kräuterbutter<sup></sup>
            </td>
            <td>2,00€</td>
          </tr>
          <tr>
            <td>
              *Röstzwiebeln<sup></sup>
            </td>
            <td>1,80€</td>
          </tr>
          <tr>
            <td>
              *Jägersoße<sup>b,c,d,f</sup>
            </td>
            <td>1,80€</td>
          </tr>
          <tr>
            <td>
              *Zigeunersoße<sup>f</sup>
            </td>
            <td>1,80€</td>
          </tr>
          <tr>
            <td>
              *Pfeffersoße<sup>b,c,d,f</sup>
            </td>
            <td>1,80€</td>
          </tr>
        </table>
      </div>
      <div>
        <table>
          <tr>
            <th>GETRÄNKE</th>
          </tr>
          <tr>
            <td>
              Veltins Pilsener <sub></sub>
            </td>
            <td>0,2l</td>
            <td>2,00€</td>
          </tr>
          <tr>
            <td>
              Veltins Pilsener <sub></sub>
            </td>
            <td>0,3l</td>
            <td>2,80€</td>
          </tr>
          <tr>
            <td>
              Veltins Pilsener <sub></sub>
            </td>
            <td>0,5l</td>
            <td>4,50€</td>
          </tr>
          <tr>
            <td>
              Weizenbier <sub></sub>
            </td>
            <td>0,5l</td>
            <td>4,50€</td>
          </tr>
          <tr>
            <td>
              Coca Cola <sub>3</sub>
            </td>
            <td>0,3l</td>
            <td>2,60€</td>
          </tr>
          <tr>
            <td>
              Coca Cola light <sub>3</sub>
            </td>
            <td>0,3l</td>
            <td>2,60€</td>
          </tr>
          <tr>
            <td>
              Coca Cola Zero <sub>3</sub>
            </td>
            <td>0,3l</td>
            <td>2,60€</td>
          </tr>
          <tr>
            <td>
              Mezzo Mix <sub>3</sub>
            </td>
            <td>0,3l</td>
            <td>2,60€</td>
          </tr>
          <tr>
            <td>
              Fanta <sub></sub>
            </td>
            <td>0,3l</td>
            <td>2,60€</td>
          </tr>
          <tr>
            <td>
              Sprite <sub></sub>
            </td>
            <td>0,3l</td>
            <td>2,60€</td>
          </tr>
          <tr>
            <td>
              VIO Schorle (versch. Sorten) <sub></sub>
            </td>
            <td>0,3l</td>
            <td>2,80€</td>
          </tr>
          <tr>
            <td>
              Fuze Tea (versch. Sorten) <sub></sub>
            </td>
            <td>0,4l</td>
            <td>2,80€</td>
          </tr>
          <tr>
            <td>
              Mineralwasser<sub></sub>
            </td>
            <td>0,25l</td>
            <td>1,90€</td>
          </tr>
          <tr>
            <td>
              Orangensaft<sub></sub>
            </td>
            <td>0,2l</td>
            <td>2,00€</td>
          </tr>
          <tr>
            <td>
              Apfelsaft<sub></sub>
            </td>
            <td>0,2l</td>
            <td>2,00€</td>
          </tr>
          <tr>
            <td>
              div. Spirituosen<sub></sub>
            </td>
            <td>2cl</td>
            <td>2,20€</td>
          </tr>
        </table>
      </div>
      <div>
        <table>
          <tr>
            <th>Zusatzstoffe und Allergene</th>
          </tr>
          <tr>
            <td>
              1 = Sämtliche Brühwürste mit Phosphat <br />
              2 = Salatmayonnaise <br />
              3 = Koffeeinhaltig <br />
              4 = Farbstoff <br />
              5 = Konservierungsmittel: Sorbinsäure, Benzoesäure <br />
              <br />
              Allergene= a: Eier / b: Getreide / c: Milch / d: Sellerie / e:
              Sesamsamen / f: kann Spuren von Ei, Gluten, Soja, Senf enthalten
            </td>
          </tr>
        </table>
      </div>
    </Slider>
  );
}
