import { useState } from "react";
import Lottie from "lottie-react-web";
import clockc from "./clockc.json";

function App() {
  const [isPaused, setIsPaused] = useState(false);

  // Функция обратного вызова для завершения цикла анимации
  function handleLoopComplete() {
    setIsPaused(true); // Установить паузу после завершения цикла анимации
    setTimeout(() => {
      setIsPaused(false); // Снять паузу через 13 секунд
    }, 20000);
  }

  return (
    <div>
      <Lottie
        options={{ animationData: clockc, loop: true }}
        isPaused={isPaused}
        eventListeners={[
          // Добавить обработчик завершения цикла анимации
          {
            eventName: "loopComplete",
            callback: handleLoopComplete,
          },
        ]}
      />
    </div>
  );
}

export default App;
