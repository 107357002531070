import React from "react";

function Open() {
  return (
    <div className="card">
      <h1>Lieferservice</h1>
      <div className="cardIcons">
        <i class="fas fa-map-marked-alt"></i>
      </div>
      <h3>Mindestbestellwert 10 €</h3>
      <p>Firmenanlieferung FREI !</p>
      <p>
        Holzhausen, Hagen, Bad Pyrmont +2,00€ <br /> Löwensen, Thal, Lügde
        +2,50€
        <br /> Weitere Orte auf Anfrage
      </p>
      <h3 className="card2">
        Letzte Lieferung <br /> 20:30 Uhr
      </h3>
    </div>
  );
}

export default Open;
