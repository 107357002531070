import React from "react";
import "../index.css";
import phone from "../img/phone.svg";
import map from "../img/map.svg";

function BottomNavMob() {
  return (
    <div className="bottomNavMob">
      <div className="bottomNavBg">
        <a href="tel:052815205">
          <div className="cardIcons2">
            <img
              style={{ height: "8vh", marginTop: "-0.5vh" }}
              src={phone}
              alt=""
            />
          </div>
        </a>
        <a href="https://www.google.com/maps/place/Holzh%C3%A4user+Grill/@51.9864605,9.2309387,17z/data=!3m1!4b1!4m5!3m4!1s0x47baf4c51b4ec017:0xa196fc390efbc412!8m2!3d51.9864605!4d9.2331274">
          <div className="cardIcons2">
            <img
              style={{ height: "8vh", marginTop: "-0.5vh" }}
              src={map}
              alt=""
            />
          </div>
        </a>
      </div>
    </div>
  );
}

export default BottomNavMob;
