import React from 'react';
import Rodal from 'rodal';
import Deliver from './Deliver'
import '../css/all.css'

// include styles
import '../css/popup.css';
import '../index.css';
import DeliverAnim from '../js/DeliverAnim'

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = { visible: false };
    }

    show() {
        this.setState({ visible: true });
        this.setState({ visible: true });
    }

    hide() {
        this.setState({ visible: false });
    }

    render() {
        return (
            <div className="MenuIconsBody">
                <div className="MenuIconsAnim" onClick={this.show.bind(this)}><DeliverAnim/></div>
                {/*<button className="MenuIcons" onClick={this.show.bind(this)}><i class="fas fa-shipping-fast"></i></button>*/}
                <h3 className="navTitel" onClick={this.show.bind(this)}>Lieferservice</h3>
                <Rodal visible={this.state.visible} onClose={this.hide.bind(this)}>
                    <div>
                    <Deliver/>
                    </div>
                </Rodal>
            </div>
        )
    }
}



export default App;
