import React from "react";
import igor from "../img/igor.jpg";
import team from "../img/team.jpg";

function Open() {
  return (
    <div className="card">
      <h1>Über uns</h1>
      <img className="pic" src={igor} alt="" />
      <h3>
        Inhaber
        <br />
        Hr. I. Vaisman
      </h3>
    </div>
  );
}

export default Open;
