import React, { useState } from 'react';
import FAQ from './FAQ';
import './index1.css'
import Impress from './Impress'
import Datenschutz from './Datenschutz'

function App () {
  const [faqs, setfaqs] = useState([
    {
      question: 'Impressum',
      answer:<Impress/>,
      open: false
    },
    {
      question: <h2 style={{ fontSize: "2.5vh", margin:0 }}>Wir sind <i class="fal fa-cookie-bite"></i> Cookies frei!!!</h2>,
      answer: <div style={{ fontSize: "2.5vh", margin:0 }}>
      <i class="fas fa-user-secret"></i>
      <p>Unsere Website verwendet keine Cookies, wie auch keine Localstorage oder Sessionstorage Technologie.</p>
      </div>,
      open: false
    },
    {
      question: 'Datenschutz­erklärung',
      answer: <Datenschutz/>,
      open: false
    },
    {
      question: 'Copyright',
      answer: '© 2021 - 2021 Holzhäuser Grill - All Rights Reserved.',
      open: false
    }
  ]);

  const toggleFAQ = index => {
    setfaqs(faqs.map((faq, i) => {
      if (i === index) {
        faq.open = !faq.open
      } else {
        faq.open = false;
      }

      return faq;
    }))
  }


  return (
    <div className="App">
      <div className="faqs">
        {faqs.map((faq, i) => (
          <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </div>
  );
}

export default App;
