import React from "react";

function Open() {
  return (
    <div className="card">
      <h1>Kontakt</h1>
      <div className="cardIcons">
        <a href="https://www.google.com/maps/place/Holzh%C3%A4user+Grill/@51.9864605,9.2309387,17z/data=!3m1!4b1!4m5!3m4!1s0x47baf4c51b4ec017:0xa196fc390efbc412!8m2!3d51.9864605!4d9.2331274">
          <i class="fas fa-map-marked-alt"></i>
        </a>
      </div>
      <a href="https://www.google.com/maps/place/Holzh%C3%A4user+Grill/@51.9864605,9.2309387,17z/data=!3m1!4b1!4m5!3m4!1s0x47baf4c51b4ec017:0xa196fc390efbc412!8m2!3d51.9864605!4d9.2331274">
        <p>
          Hagener Straße 6 <br /> 31812 Bad Pyrmont <br />
        </p>
      </a>
      <div className="cardIcons">
        <a href="tel:052815205">
          <i class="fas fa-phone-rotary"></i>
        </a>
      </div>
      <a href="tel:052815205">
        <p>05281-5205</p>
      </a>

      <div className="cardIcons">
        <a href="https://web.facebook.com/Holzh%C3%A4user-Grill-Bad-Pyrmont-948510635326060">
          <i class="fab fa-facebook-square"></i>
        </a>
      </div>
      <a href="https://web.facebook.com/Holzh%C3%A4user-Grill-Bad-Pyrmont-948510635326060">
        <p>Besuchen sie uns auf Facebook</p>
      </a>
    </div>
  );
}

export default Open;
