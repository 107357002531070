import React, { Component } from 'react';
import Lottie from 'lottie-react-web'
import header from './header.json'
 
const App = () => (
    <Lottie
      options={{
        animationData: header,
      }}
    />
  )
   
  export default App