import React from 'react'
import App from './Accord/App'
function Info() {
  return (
    <div className="card">
    <div className="MenuIcons"><i class="fas fa-info"></i></div>
      <App/>
    </div>
  )
}

export default Info