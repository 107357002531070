import React from "react";
import Grill from "../img/Grill.png";

function Open() {
  return (
    <div className="card">
      <h1>Grill Service</h1>
      <img className="pic3" src={Grill} alt="" />
    </div>
  );
}
export default Open;
