import React from "react";
import ST2 from "../img/ST2.png";

function Open() {
  return (
    <div className="card">
      <h1>Angebote</h1>
      <img className="pic3" src={ST2} alt="" />
    </div>
  );
}

export default Open;
