import React from "react";
import Rodal from "rodal";
import Covid from "./Covid";
import "../css/all.css";

// include styles
import "../css/popup.css";
import "../index.css";
import CovidAnim from "../js/CovidAnim";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  show() {
    this.setState({ visible: true });
    this.setState({ visible: true });
  }

  hide() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <div className="MenuIconsBody">
        <div className="MenuIconsAnim" onClick={this.show.bind(this)}>
          <CovidAnim />
        </div>
        {/*<button className="MenuIcons" onClick={this.show.bind(this)}><div className="card2"><i class="fas fa-people-arrows"></i></div></button>*/}
        <h3 className="navTitel" onClick={this.show.bind(this)}>
          Angebote
        </h3>
        <Rodal visible={this.state.visible} onClose={this.hide.bind(this)}>
          <div>
            <Covid />
          </div>
        </Rodal>
      </div>
    );
  }
}

export default App;
