import Lottie from "lottie-react-web";
import covid from "./sale.json";

const App = () => (
  <Lottie
    options={{
      animationData: covid,
    }}
  />
);

export default App;
