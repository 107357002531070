import React from 'react';
import Rodal from 'rodal';
import About from './About'
import '../css/all.css'

// include styles
import '../css/popup.css';
import '../index.css';
import AboutAnim from '../js/AboutAnim'

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = { visible: false };
    }

    show() {
        this.setState({ visible: true });
        this.setState({ visible: true });
    }

    hide() {
        this.setState({ visible: false });
    }

    render() {
        return (
            <div className="MenuIconsBody">
                <div className="MenuIconsAnim" onClick={this.show.bind(this)}><AboutAnim/></div>
                {/*<button className="MenuIcons" onClick={this.show.bind(this)}><i class="fas fa-users"></i></button>*/}
                <h3 className="navTitel" onClick={this.show.bind(this)}>Über uns</h3>
                <Rodal visible={this.state.visible} onClose={this.hide.bind(this)}>
                    <div>
                    <About/>
                    </div>
                </Rodal>
            </div>
        )
    }
}



export default App;
